
import Vue from "vue";

import MyFormattedDate from "@/components/MyFormattedDate.vue";
import MyDocument from "@/components/MyDocument.vue";

import { Document, Procedure, Renewal, Snackbar } from "@/interfaces";

import http from "@/http";

import axios from "axios";

import { mdiCheck } from "@mdi/js";
import { mapState } from "vuex";

export interface Body {
  name: string;
  idList: string;
  desc: string;
}
export interface DataType {
  procedure?: Procedure;
  document?: Document;
}

export default Vue.extend({
  components: {
    MyFormattedDate,
    MyDocument,
  },
  data(): DataType {
    return {
      procedure: undefined,
      document: undefined,
    };
  },
  computed: {
    ...mapState(["user"]),
    hasAttachments(): boolean {
      if (this.procedure == null) {
        return false;
      }

      return this.procedure.attachments.length > 0;
    },
    isDoing() {
      if (this.procedure == null) {
        return false;
      }

      if (this.procedure.status === "進行中") {
        return true;
      }

      return false;
    },
    isDone() {
      if (this.procedure == null) {
        return false;
      }

      if (this.procedure.status === "完了") {
        return true;
      }

      return false;
    },
    icon() {
      return {
        mdiCheck,
      };
    },
    breadcrumbs() {
      let text = "";
      if (this.procedure) {
        text = `# ${this.procedure.id} ${this.procedure.contract.room.building.name}
        ${this.procedure.contract.room.display_number}`;
      }
      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/",
        },
        {
          text: "契約更新",
          disabled: false,
          to: "/procedures",
        },
        {
          text,
          disabled: true,
        },
      ];
    },
  },
  async mounted() {
    const { id } = this.$route.params;

    const url = `/procedures/${id}`;

    const { data } = await http.get<Procedure>(url);

    this.procedure = data;

    if (this.procedure.renewal?.document_id) {
      await this.fetch();
    }
  },
  methods: {
    async fetch() {
      if (
        this.procedure == null ||
        this.procedure.renewal?.document_id == null
      ) {
        return false;
      }
      const url = `/renewals/${this.procedure?.renewal?.id}`;

      const { data } = await http.get<Renewal>(url);

      this.document = data.document;
    },
    async change(files: File[]) {
      if (this.procedure == null) {
        return false;
      }

      const formData = new FormData();

      if (files.length === 0) {
        return;
      }

      const file = files[0];

      formData.append("file", file);

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      const url = `/renewals/${this.procedure?.renewal?.id}`;

      try {
        const { data } = await http.post(url, formData, {
          headers,
        });

        this.procedure.attachments.push(data);
      } catch (error) {
        const snackbar: Snackbar = {
          show: true,
          content: "ファイルに不備があるようです",
          color: "success",
        };

        this.$store.commit("setSnackbar", snackbar);
      }
    },
    async click() {
      if (this.procedure == null) {
        return;
      }

      const { contractor_id } = this.procedure.contract.contract_history;

      const url = `/members/${contractor_id}/notices`;

      const params = {
        title: "手続きが完了しました",
        content: "手続きが完了しました",
        status: "publish",
        target: "mmeber",
      };

      const { data } = await http.post<Procedure>(url, {
        ...params,
      });

      const snackbar: Snackbar = {
        show: true,
        content: "送信しました",
        color: "success",
      };

      this.$store.commit("setSnackbar", snackbar);
    },
    async done() {
      if (this.procedure == null) {
        return;
      }

      const url = `/procedures/${this.procedure.id}`;

      const { data } = await http.put<Procedure>(url, {
        status: 3,
      });

      this.procedure = data;
    },
    async send() {
      if (this.procedure == null || this.procedure.renewal == null) {
        return;
      }

      const url = `/renewals/${this.procedure?.renewal?.id}`;

      const { data } = await http.put<Renewal>(url);

      this.procedure.renewal = data;
    },
    async remove() {
      if (this.procedure == null || this.procedure.renewal == null) {
        return;
      }

      const url = `/renewals/${this.procedure?.renewal?.id}`;

      await http.delete(url);

      this.procedure.attachments = [];
      this.procedure.renewal.document_id = undefined;
      this.document = undefined;
    },
    async post(data: Body) {
      const { VUE_APP_TRELLO_KEY, VUE_APP_TRELLO_TOKEN } = process.env;

      const url = `https://api.trello.com/1/cards`;

      const params = {
        key: VUE_APP_TRELLO_KEY,
        token: VUE_APP_TRELLO_TOKEN,
      };

      await axios.post(url, data, {
        params,
      });

      const snackbar: Snackbar = {
        show: true,
        content: "リクエストを送信しました",
        color: "success",
      };

      this.$store.commit("setSnackbar", snackbar);
    },
    async cancel() {
      if (this.procedure == null) {
        return;
      }

      if (window.confirm("本当にキャンセルしますか?")) {
        return;
      }

      const url = `/procedures/${this.procedure.id}`;

      await http.delete(url);

      const snackbar: Snackbar = {
        show: true,
        content: "キャンセルしました",
        color: "success",
      };

      this.$store.commit("setSnackbar", snackbar);

      this.$router.push("/procedures");
    },
    download() {
      if (this.procedure == null) {
        return false;
      }

      const { attachments } = this.procedure;

      window.location.href = attachments[0].url;
    },
  },
});
