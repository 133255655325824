
import Vue, { PropType } from "vue";

import { Document } from "@/interfaces";

export default Vue.extend({
  props: {
    document: {
      type: Object as PropType<Document>,
      required: true,
    },
  },
  computed: {
    status(): string {
      switch (this.document.status) {
        case 1:
          return "先方確認中";
        case 2:
          return "締結済";
        case 3:
          return "取消、または却下";
        default:
          return "下書き";
      }
    },
  },
});
